import axios from 'axios'

// var ___g_URL_BASE = 'https://weixinfm.leadfirst.top/api/';
var ___g_URL_BASE = 'http://47.93.213.107:5000/api/';

class omaha_net {
    get(url, cb) {
        axios
            .get(___g_URL_BASE + url)
            .then(response => { cb(response); })
            .catch(function (error) { // 请求失败处理
                console.log(error);
            });
    }
    post(url, params, cb) {
        var param = new FormData();
        // let uid = sessionStorage.getItem("uid");//用户ID
        let token = localStorage.getItem("token");//登录反馈的令牌
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',//application/x-www-form-urlencoded
                // 'uid': uid,
                'Token': token,
            }
        }
        param.append('data', JSON.stringify(params));
        axios.post(___g_URL_BASE + url, param, config)
            .then(response => { cb(response); })
            .catch(function (error) { // 请求失败处理
                console.log(error);//
            });
    }
    // 上传图片
    post2(url, params, cb) {
        var param = new FormData();
        // let uid = sessionStorage.getItem("uid");//用户ID
        let token = localStorage.getItem("token");//登录反馈的令牌
        let config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                // 'uid': uid,
                'Token': token
            }
        }
        param.append('multipartFile', params);
        console.log(param, 'paramparamparamparam');
        axios.post(___g_URL_BASE + url, param, config)
            .then(response => { cb(response); })
            .catch(function (error) { // 请求失败处理
                console.log(error);//
            });
    }

    postlogin(url, params, cb) {
        var param = new FormData();
        // let uid = sessionStorage.getItem("uid");//用户ID
        // let token = sessionStorage.getItem("token");//登录反馈的令牌
        // console.log(token, '111');
        let config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                // 'uid': uid,
                // 'token': token
            }
        }
        param.append('data', JSON.stringify(params));
        axios.post(___g_URL_BASE + url, param, config)
            .then(response => { cb(response); })
            .catch(function (error) { // 请求失败处理
                console.log(error);//
            });
    }

}
const __omaha_net = new omaha_net();
export default __omaha_net;



